import { h, Ref } from 'preact';
import { forwardRef } from 'preact/compat';

import * as S from './styled';

interface Props extends h.JSX.HTMLAttributes<HTMLInputElement> {
	containerClassName?: string;
	error?: string;
}

const Input = forwardRef(
	({ containerClassName, error, ...inputProps }: Props, ref: Ref<HTMLInputElement>) => {
		return (
			<S.Container data-error-message={error} className={containerClassName}>
				<S.Input ref={ref} {...inputProps} />
			</S.Container>
		);
	}
);

export default Input;
