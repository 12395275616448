import styled from 'styled-components';

export const Title = styled.h2`
	font-family: Helvetica Neue;
	font-size: ${18 / 16}rem;
	font-style: normal;
	font-weight: 500;
	line-height: 16px;
	letter-spacing: 0em;
	text-align: center;
`;

export const Text = styled.p`
	font-family: Helvetica Neue;
	font-size: ${14 / 16}rem;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: 0em;
	text-align: center;
`;

export const RingWithCheck = styled.div`
	position: relative;
`;

export const CheckIcon = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	svg {
		transform: scale(1.5);
	}
`;
