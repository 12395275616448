import styled from 'styled-components';

export interface ContainerProps {
	formState: string;
}

export const Container = styled.div`
	display: ${({ formState }) => (formState === 'credit-card' ? 'flex' : 'none')};
	flex-direction: column;
	gap: 32px;
	min-height: 264px;
`;

export const InputContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

export const RecurringPaymentsCopy = styled.p`
	font-family: Helvetica Neue;
	font-size: ${12 / 16}rem;
	line-height: 16px;
	text-align: center;
`;

export const Label = styled.label`
	font-size: 14px;
	font-weight: 500;
	line-height: 22px;
	letter-spacing: 0.005em;
	text-align: left;
	display: flex;
	align-items: center;
	gap: 4px;

	& > input[type='radio'] {
		/* remove standard background appearance */
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		/* create custom radiobutton appearance */
		display: inline-block;
		width: 10px;
		height: 10px;
		padding: 2px;
		/* background-color only for content */
		background-clip: content-box;
		border: 1px solid #000;
		border-radius: 50%;
		margin: 0;
	}

	& > input[type='radio']:checked {
		background-color: black;
	}
`;

export const SavedCard = styled.div`
	border: 1px solid rgba(54, 55, 64, 0.12);
	border-radius: 4px;
	display: flex;
	align-items: center;
	padding: 8px 12px;
	gap: 8px;

	> p {
		font-family: Helvetica Neue;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 22px;
		letter-spacing: 0.005em;
		text-align: left;
	}
`;
