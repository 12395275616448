export const defaultElementStyles = {
	base: {
		'color': '#363740',
		'fontWeight': 500,
		'fontFamily':
			'Helvetica Neue, -apple-system, BlinkMacSystemFont, Segoe UI,Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, sans-serif',
		'fontSize': '16px',
		'fontSmoothing': 'antialiased',
		'backgroundColor': 'transparent',

		'::placeholder': {
			color: '#989898',
			fontWeight: 'normal',
		},
		':-webkit-autofill': {
			color: '#e39f48',
		},
	},
	invalid: {
		'color': '#E25950',

		'::placeholder': {
			color: '#FFCCA5',
		},
	},
};

export const defaultElementClasses = {
	focus: 'focused',
	empty: 'empty',
	invalid: 'invalid',
};

export const defaultPaymentRequestButtonStyle: stripe.elements.PaymentRequestButtonStyleOptions = {
	theme: 'dark',
	height: '48px',
};
