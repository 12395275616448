import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import * as Type from './types';
import * as utils from './utils';
import useLogin from '../shared/hooks/useLogin';

import BasicInfo from './components/BasicInfo';
import BankingInfo from './components/BankingInfo';

import * as S from './styled';
import Error from './components/Error';

export default function App({
	oauth2Client,
	tapperApiClient,
	onKYCSuccess,
}: Type.KYCComponentProps) {
	const { isLoggedIn } = useLogin({
		requireLogin: true,
		oauth2Client,
		tapperApiClient,
	});

	const [showGenericError, setShowGenericError] = useState<boolean>(false);
	const [kycStage, setKycStage] = useState<Type.KYCStage>('');
	const [merchantData, setMerchantData] = useState<Type.MerchantData>(
		utils.defaultMerchantData
	);

	useEffect(() => {
		isLoggedIn && getMerchantData();
		async function getMerchantData() {
			tapperApiClient
				.getMerchantId()
				.then(id => tapperApiClient.getMerchantInfo(id))
				.then(data => {
					setMerchantData(data);
				})
				.catch(() => setShowGenericError(true));
		}
	}, [isLoggedIn, tapperApiClient]);

	useEffect(() => {
		if (isLoggedIn && merchantData.id) {
			!merchantData.first_name || !merchantData.last_name
				? setKycStage('basic')
				: setKycStage('banking');
		}

		setShowGenericError(false);
	}, [isLoggedIn, merchantData]);

	return (
		<S.Container>
			{showGenericError && <Error />}
			{!showGenericError && !isLoggedIn && <S.Title>Loading account details...</S.Title>}
			{!showGenericError && isLoggedIn && !merchantData.id && (
				<S.Title>Obtaining merchant information ...</S.Title>
			)}
			{kycStage === 'basic' && (
				<BasicInfo
					merchant={merchantData}
					tapperApiClient={tapperApiClient}
					onKYCSuccess={onKYCSuccess}
				/>
			)}
			{kycStage === 'banking' && (
				<BankingInfo
					merchant={merchantData}
					tapperApiClient={tapperApiClient}
					onKYCSuccess={onKYCSuccess}
				/>
			)}
		</S.Container>
	);
}
