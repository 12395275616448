const EMAIL_REGEXP =
	/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function validateEmail(email: string) {
	if (!email) {
		return 'Email is required.';
	}

	return EMAIL_REGEXP.test(email) ? '' : 'Please enter a valid email.';
}

export function validatePassword(password: string) {
	if (!password) {
		return 'Password is required.';
	}

	const passwordStrengthList = [];

	if (password.length >= 10) passwordStrengthList.push('password_10_chars');
	if (password.match(/\d/)) passwordStrengthList.push('password_1_number');
	if (password.match(/[A-Z]/)) passwordStrengthList.push('password_1_upper');
	if (password.match(/\W/)) passwordStrengthList.push('password_1_symbol');

	if (passwordStrengthList.length === 4) {
		return '';
	}

	return 'Password must be at least 10 characters and include 1 number, 1 uppercase, and 1 symbol.';
}

export const formatCurrency = (amount: number, currency: string) => {
	return new Intl.NumberFormat('en-EN', {
		style: 'currency',
		currency,
		minimumFractionDigits: 0,
		maximumFractionDigits: 2,
	}).format(amount);
};

export const supportedCountries = {
	AT: 'Austria',
	BE: 'Belgium',
	BG: 'Bulgaria',
	CY: 'Cyprus',
	CZ: 'Czech Republic',
	DK: 'Denmark',
	EE: 'Estonia',
	FI: 'Finland',
	FR: 'France',
	DE: 'Germany',
	GR: 'Greece',
	HU: 'Hungary',
	IE: 'Ireland',
	IT: 'Italy',
	LV: 'Latvia',
	LT: 'Lithuania',
	LU: 'Luxembourg',
	MT: 'Malta',
	NL: 'Netherlands',
	NO: 'Norway',
	PL: 'Poland',
	PT: 'Portugal',
	RO: 'Romania',
	SK: 'Slovakia',
	SI: 'Slovenia',
	ES: 'Spain',
	SE: 'Sweden',
	CH: 'Switzerland',
	GB: 'United Kingdom',
	US: 'United States',
};
