import styled from 'styled-components';
import * as InputGlobal from '../shared/components/Input/styled';

interface ContainerProps {
	formType: string;
}

export const Container = styled.div<ContainerProps>`
	font-family: Helvetica Neue, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
		Ubuntu, Cantarell, Fira Sans, Droid Sans, sans-serif;
	text-align: ${props => (props.formType === 'thank-you' ? 'center' : 'left')};

	${InputGlobal.Container} {
		background-color: white;
		border-color: #989898;
		font-weight: normal;
	}

	display: flex;
	gap: 32px;
	flex-direction: column;
`;

interface FormContainerProps {
	visible?: boolean;
}

export const FormContainer = styled.div<FormContainerProps>`
	display: ${props => (props.visible ? 'block' : 'none')};
	padding-top: 1.5rem;
`;

export const FlexColContainer = styled.div`
	display: flex;
	gap: 32px;
	flex-direction: column;
`;

// Note: I noticed composing (e.g. styled(FlexColContainer)) was not working
export const PaymentRequestButtonContainer = styled.div`
	display: flex;
	gap: 32px;
	flex-direction: column;
`;

export const PaymentRequestButton = styled.div`
	overflow: hidden;
	border-radius: 100px;
	background-color: black;
	color: white;
	transition: opacity 100ms linear;
	opacity: 0;

	/*
		It's explicit because of the Stripe's payment request button
		options. The button is withing the iframe, and it's not possible
		to set body's font-size thus making rem's behaving unpredictably.
		'height' can be overridden with 'paymentRequestButtonStyle.height' prop.
	*/
	height: 48px;
`;

export const CardInput = styled(InputGlobal.Container)`
	height: 40px;
`;

export const SignInPrompt = styled.p`
	padding: 15px;
	line-height: 20px;
	border: 1px solid rgba(60, 66, 87, 0.12);
	border-radius: 4px;
	font-size: ${14 / 16}rem;
	color: #363740;
	background: rgba(54, 55, 64, 0.05);
	a {
		font-weight: 700;
		text-decoration: underline;
		text-transform: uppercase;
	}
`;
