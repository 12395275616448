import { h } from 'preact';
import { useState } from 'preact/hooks';

import SocialLogin from './components/SocialLogin';
import Divider from '../shared/components/Divider';

import useEmailField from '../shared/hooks/useEmailField';
import usePasswordField from '../shared/hooks/usePasswordField';

import { GuestRegistrationComponentProps } from './types';
import * as S from './styled';

import Button from '../shared/components/Button';
import Input from '../shared/components/Input';

export default function App({
	email,
	tapperSSOProvider,
	onRegistrationSuccess,
}: GuestRegistrationComponentProps) {
	const [isRegistered, setIsRegistered] = useState(false);
	const [error, setError] = useState(null);
	const { emailRef, emailError, validateEmailField /* setEmailError */ } =
		useEmailField();
	const { passwordRef, passwordError, validatePasswordField /* setPasswordError */ } =
		usePasswordField();

	if (isRegistered) {
		return (
			<S.RegistrationSuccess>{email} successfully registered!</S.RegistrationSuccess>
		);
	}

	return (
		<S.Container>
			<S.DividerWithVerticalPadding noMargin={true} />
			<S.Title>
				Create a Contribute.to account to track your contributions. You can also save your
				card for faster checkout.
			</S.Title>
			<Input
				disabled={true}
				error={emailError}
				placeholder="Email"
				ref={emailRef}
				type="email"
				value={email ?? ''}
			/>
			<S.PasswordInputContainer>
				<Input
					disabled={error}
					error={passwordError}
					placeholder="Add Password"
					ref={passwordRef}
					type="password"
				/>
			</S.PasswordInputContainer>
			<Button onClick={handleCreateAccountClick} disabled={error}>
				Create Your Account
			</Button>
			{error && (
				<div>
					<S.ErrorMessage>
						{error.message}
						{'. '}
					</S.ErrorMessage>
					{error.code === 'address_in_use' && (
						<S.ErrorMessage>
							Log in to <a href="https://contribute.to/login">Contribute.to</a> account.
						</S.ErrorMessage>
					)}
				</div>
			)}
			{tapperSSOProvider.redirectBack && (
				<SocialLogin socialLoginUrls={tapperSSOProvider.getSocialLoginUrls()} />
			)}
		</S.Container>
	);

	async function handleCreateAccountClick() {
		if (validateForm()) {
			try {
				await tapperSSOProvider.register(passwordRef?.current?.value);
				setIsRegistered(true);
				onRegistrationSuccess();
			} catch (error) {
				setError(error);
			}
		}
	}

	function validateForm() {
		const isValidPassword = validatePasswordField();
		const isValidEmail = validateEmailField();
		return isValidPassword && isValidEmail;
	}
}
