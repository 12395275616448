import { h } from 'preact';

export default function Error() {
	return (
		<div>
			<h1>Oops!</h1>
			<p>An error has occurred. Please try again.</p>
		</div>
	);
}
