import { useState, useRef, useEffect } from 'preact/hooks';
import { validateEmail } from '../../utils';

const useEmailField = () => {
	const [emailError, setEmailError] = useState<string>('');
	const emailRef = useRef<HTMLInputElement>();

	useEffect(() => {
		const email = emailRef.current;

		email?.addEventListener('change', validateEmailField);
		return () => email?.removeEventListener('change', validateEmailField);
	}, []);

	function validateEmailField() {
		const emailValidationMessage = validateEmail(emailRef.current.value);
		setEmailError(emailValidationMessage);
		return !emailValidationMessage;
	}

	return {
		emailRef,
		emailError,
		validateEmailField,
		setEmailError,
	};
};

export default useEmailField;
