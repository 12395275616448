import { h } from 'preact';

export default function Success() {
	return (
		<div>
			<h1>Thank you!</h1>
			<p>Your details have been saved successfully.</p>
		</div>
	);
}
