/*
	TODOs:

	* Maybe we separate out some things
		* e.g. MerchantService, UserService, PaymentService, etc...

*/

import axios, { AxiosInstance } from 'axios';
import { MerchantData } from '../../KYC/types';
import {
	MakeContributionResponse,
	PurchaseItem,
	Tab,
	TapperPaymentIntent,
} from './types';

class TapperApiClient {
	axios: AxiosInstance;

	constructor({ baseURL }: { baseURL: string }) {
		this.axios = axios.create({ baseURL });
	}

	setAuthorizationHeader(header: string) {
		this.axios.defaults.headers.common['Authorization'] = header;
	}

	async makeContribution(payload: PurchaseItem) {
		try {
			const { data } = await this.axios.post<MakeContributionResponse>('/v1/purchase', {
				offering_id: payload.offeringId,
				payment_model: payload.paymentModel,
				price: payload.price,
				sales_model: payload.salesModel,
				summary: payload.summary,
			});

			return data.tab;
		} catch (error) {
			// Payment required
			if (error?.response?.status === 402) {
				return error.response.data.tab as Tab;
			}

			throw error;
		}
	}

	async getUserTab() {
		const { data } = await this.axios.get<Tab[]>('/v1/tabs');

		// Currently there is no way to get a full tab without knowing the tab id
		// other than this.
		// This is just a temporary solution agreed upon in Slack cross team channel.
		return data?.filter(tab => tab.currency === 'USD' && tab.status === 'full')?.[0];
	}

	async startTapperPaymentIntent(tabId: string) {
		const { data } = await this.axios.get<TapperPaymentIntent>(
			`/v1/payment/start/${tabId}`
		);
		return data;
	}

	async getMerchantId() {
		try {
			const { data } = await this.axios.get('/v1/auth/roles/me');

			if (!data[0]) {
				throw new Error(`Couldn't find merchant ID.`);
			}

			return data[0]?.resource_id;
		} catch (error) {
			throw error;
		}
	}

	async getMerchantInfo(merchantId: string) {
		try {
			if (!merchantId) {
				throw new Error(`Invalid merchant ID.`);
			}

			const { data } = await this.axios.get<MerchantData>(`/v2/merchants/${merchantId}`);

			return data;
		} catch (error) {
			throw error;
		}
	}

	async updateMerchantInfo(merchantData) {
		try {
			if (!merchantData.first_name) {
				throw new Error('Required field missing: First Name.');
			}

			if (!merchantData.last_name) {
				throw new Error('Required field missing: Last Name.');
			}

			if (!merchantData.dob) {
				throw new Error('Required field missing: Date of Birth.');
			}

			if (!merchantData.country) {
				throw new Error('Required field missing: Country.');
			}

			const { data } = await this.axios.patch(
				`/v2/merchants/${merchantData.id}`,
				merchantData
			);

			return data;
		} catch (error) {
			const { data } = error.response;

			if (data.error?.code === 'validation_error') {
				throw new Error(data.error?.errors[0]?.message);
			} else {
				throw new Error(data.error?.message);
			}
		}
	}

	async getMerchantRequirements(merchantId: string) {
		try {
			if (!merchantId) {
				throw new Error(`Invalid merchant ID.`);
			}

			const { data } = await this.axios.get<MerchantData>(
				`/v2/merchants/${merchantId}/requirements`
			);

			return data;
		} catch (error) {
			throw error;
		}
	}

	async getMerchantMembers(merchantId: string) {
		try {
			if (!merchantId) {
				throw new Error(`Invalid merchant ID.`);
			}

			const { data } = await this.axios.get<unknown>(
				`/v1/merchants/${merchantId}/members`
			);

			return data;
		} catch (error) {
			throw error;
		}
	}

	async getBankInfo(merchantId: string) {
		try {
			if (!merchantId) {
				throw new Error(`Invalid merchant ID.`);
			}

			const { data } = await this.axios.get<MerchantData>(
				`/v2/merchants/${merchantId}/bank_account`
			);

			return data;
		} catch (error) {
			throw error;
		}
	}

	async createBankAccount({ id, ...merchantData }) {
		try {
			if (!merchantData.account_number) {
				throw new Error('Required field missing: Account Number.');
			}

			if (!merchantData.currency) {
				throw new Error('Required field missing: Currency.');
			}

			if (!merchantData.country) {
				throw new Error('Required field missing: Country.');
			}

			const { data } = await this.axios.post(
				`/v2/merchants/${id}/bank_account`,
				merchantData
			);
			return data;
		} catch (error) {
			const { data } = error.response;

			if (data.error?.code === 'validation_error') {
				throw new Error(data.error?.errors[0]?.message);
			} else {
				throw new Error(data.error?.message);
			}
		}
	}

	async createRecurringContribution({
		billing_interval,
		merchant_id,
		price,
	}: {
		billing_interval: string;
		merchant_id: string;
		price: { amount: number; currency: string };
	}) {
		try {
			const { data } = await this.axios.post<unknown>(`/v1/purchase/recurring`, {
				billing_interval,
				merchant_id,
				price: {
					amount: price.amount,
					currency: price.currency,
				},
			});
			return data;
		} catch (error) {
			throw error;
		}
	}

	async getDefaultPaymentMethod() {
		try {
			const { data } = await this.axios.get<unknown>(`/v1/payment/methods/default`);
			return data;
		} catch (error) {
			console.log({ error });
			throw error;
		}
	}

	async preparePaymentMethod() {
		try {
			const { data }: any = await this.axios.post<unknown>(`/v1/payment/methods/prepare`);
			return { clientSecret: data.client_secret };
		} catch (error) {
			throw error;
		}
	}

	async confirmPayment(tabId: string) {
		try {
			const { data }: any = await this.axios.post<unknown>(
				`/v1/payment/confirm/${tabId}`
			);
			return data;
		} catch (error) {
			throw error;
		}
	}
}

export default TapperApiClient;
