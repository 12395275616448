import 'preact/devtools';
import { ComponentFactory } from 'preact';
import habitat from 'preact-habitat';
import { SelectorMissingError } from './SelectorMissingError';
import TapperApiClient from '../api/TapperApiClient';

interface RenderComponentProps<T> {
	/**
	 * Element selector determining where the component
	 * will be mounted in the DOM.
	 */
	selector: string;
	/**
	 * Component we want to mount.
	 */
	Component: ComponentFactory<T>;
	/**
	 * Props we want to pass to te Component.
	 */
	componentProps?: T;
}

export default class BaseComponent<TComponentProps> {
	#selector: string;
	testMode: boolean;

	constructor({ testMode }: { testMode: boolean }) {
		this.testMode = testMode;
	}

	renderComponent(options: RenderComponentProps<TComponentProps>) {
		if (!options.selector) {
			throw new SelectorMissingError();
		}

		this.#selector = options.selector;

		habitat<TComponentProps>(options.Component).render({
			selector: options.selector,
			clean: true,
			defaultProps: options.componentProps,
		});
	}

	destroyComponent() {
		const element = document.querySelector(this.#selector);
		element.parentElement.removeChild(element);
	}
}
