import { h } from 'preact';

import * as WidgetStyles from './styled';
import * as S from '../../styled';

export default function ThankYouWidget() {
	return (
		<S.Container formType="thank-you">
			<WidgetStyles.RingWithCheck>
				<svg
					width="60"
					height="60"
					viewBox="0 0 60 60"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<circle cx="30" cy="30" r="30" fill="#5FC146" />
				</svg>
				<WidgetStyles.CheckIcon>
					<svg
						width="12"
						height="12"
						viewBox="0 0 19 19"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M16.3038 1.56493L16.3037 1.56523L8.2173 14.9629L2.41869 9.04129L2.41785 9.04044C2.17885 8.79753 1.85643 8.67433 1.52422 8.71557C1.19501 8.75644 0.91335 8.95109 0.728686 9.23148L0.728679 9.23147L0.727126 9.23386C0.382181 9.76416 0.42801 10.5369 0.881144 11.0001L0.881329 11.0003L7.65423 17.9162L7.65548 17.9175C7.85479 18.1196 8.12584 18.25 8.42356 18.25C8.47045 18.25 8.51716 18.2471 8.56358 18.2413L8.57146 18.2403L8.5793 18.239C8.92153 18.1852 9.19992 17.9693 9.37089 17.6878L9.37162 17.6866L18.1985 3.06183L18.199 3.06099C18.5242 2.51975 18.4587 1.74909 17.9858 1.30322C17.7359 1.06468 17.4056 0.957793 17.0758 1.01513C16.75 1.07175 16.477 1.27847 16.3038 1.56493Z"
							fill="white"
							stroke="white"
						/>
					</svg>
				</WidgetStyles.CheckIcon>
			</WidgetStyles.RingWithCheck>
			<WidgetStyles.Title>Thank you for your support!</WidgetStyles.Title>
			<WidgetStyles.Text>
				Your support means authors can continue providing you with content you love.
			</WidgetStyles.Text>
		</S.Container>
	);
}
