/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import * as qs from 'query-string';
import axios from 'axios';
import {
	OAuth2ClientConfig,
	GetTokenParams,
	GetAuthorizationUriParams,
	GetTokenResponse,
	RefreshTokenResponse,
	BuildTokenEndpointParams,
} from './types';
import CachedString from './CachedString';
import { pkceChallengeFromVerifier, encodeJsonToState, getRandomString } from './helpers';

export class OAuth2Client {
	providerId: OAuth2ClientConfig['providerId'];
	clientId: OAuth2ClientConfig['clientId'];
	audience: OAuth2ClientConfig['audience'];
	scope: OAuth2ClientConfig['scope'];
	authorizationServerUri: OAuth2ClientConfig['authorizationServerUri'];
	tokenServerUri: OAuth2ClientConfig['tokenServerUri'];
	redirectUri: OAuth2ClientConfig['redirectUri'];

	private state: CachedString;
	private codeVerifier: CachedString;
	private refToken: CachedString; // method is already called refreshToken

	constructor(config: OAuth2ClientConfig) {
		this.audience = config.audience;
		this.authorizationServerUri = config.authorizationServerUri;
		this.clientId = config.clientId;
		this.providerId = config.providerId;
		this.redirectUri = config.redirectUri;
		this.scope = config.scope;
		this.tokenServerUri = config.tokenServerUri;

		this.state = new CachedString(`${this.providerId}.oauth2_state`);
		this.codeVerifier = new CachedString(`${this.providerId}.oauth2_code_verifier`);
		this.refToken = new CachedString(`${this.providerId}.oauth2_refresh_token`);
	}

	private async callTokenEndpoint<T>(params: BuildTokenEndpointParams) {
		// Since token server expects application/x-www-form-urlencoded
		// in body, URLs searchParams provides us with the form data shape
		// so it's nice utility to build them like this.
		const tokenEndpoint = new URL(this.tokenServerUri);
		for (const [key, value] of Object.entries(params)) {
			tokenEndpoint.searchParams.append(key, value);
		}

		const { data } = await axios.post(this.tokenServerUri, tokenEndpoint.searchParams, {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				Accept: 'application/json',
				Authorization: `${this.clientId}:none`,
			},
		});

		// cache refresh token
		if (data.refresh_token) {
			this.refToken.setValue(data.refresh_token);
		}

		return data as T;
	}

	/**
	 * Helper method to check if there's a refresh token.
	 */
	hasRefreshToken() {
		return !!this.refToken?.getValue();
	}

	/**
	 * Generates a URL that points to the authorization server.
	 * URL is supposed to be use to redirect the user to the
	 * authorization server in order to complete the login/registration
	 * and retrieve an access token.
	 */
	async getAuthorizationUri(): Promise<string> {
		const state = {
			// slug,
			redirect_uri: this.redirectUri + '/',
			client_id: this.clientId,
			// ...(NETLIFY && { netlify_redirect_uri: resolveAppUrl() }),
		};

		const encodedState = encodeJsonToState(state);
		const codeVerifier = getRandomString(128);
		this.state.setValue(encodedState);
		this.codeVerifier.setValue(codeVerifier);

		const codeChallenge = await pkceChallengeFromVerifier(codeVerifier);
		const queryParams = qs.stringify({
			client_id: this.clientId,
			scope: this.scope,
			audience: this.audience,
			state: encodedState,
			redirect_uri: this.redirectUri,
			code_challenge: codeChallenge,
			code_challenge_method: 'S256',
			response_type: 'code',
		});

		return `${this.authorizationServerUri}?${queryParams}`;
	}

	/**
	 * Calls /oauth2/token endpoint of authorization server.
	 * If successful, the endpoint will return an access token
	 * and refresh token.
	 *
	 * Refresh token will be stored in sessionStorage.
	 * Note that it would be more secure if it's stored in
	 * HttpOnly cookie.
	 */
	async getToken({ state, code }: GetTokenParams): Promise<GetTokenResponse> {
		const cachedState = this.state.getValue();
		const cachedCodeVerifier = this.codeVerifier.getValue();

		if (state !== cachedState) {
			throw new Error('Possible malicious action! Codes do not match.');
		}

		if (!cachedCodeVerifier) {
			throw new Error('Code verifier is missing. You probably forgot to store it.');
		}

		return await this.callTokenEndpoint<GetTokenResponse>({
			client_id: this.clientId,
			grant_type: 'authorization_code',
			code: code,
			code_verifier: cachedCodeVerifier,
			redirect_uri: this.redirectUri,
		});
	}

	/**
	 * The same way getToken calls /oauth2/token endpoint,
	 * refreshToken does the same but with different params.
	 * It's used to get a new access token providing the
	 * refresh token.
	 */
	async refreshToken(): Promise<RefreshTokenResponse> {
		const token = this.refToken.getValue();

		if (!token) {
			throw new Error('Refresh token is missing.');
		}

		return await this.callTokenEndpoint<RefreshTokenResponse>({
			client_id: this.clientId,
			grant_type: 'refresh_token',
			refresh_token: token,
		});
	}
}
