/*
	TODOs:

	* Make this all work with Netlify 
	* Make use of getEnv for the constants
*/

// import { LP_OAUTH2_AUTH_URL, LP_OAUTH2_TOKEN_URL, NETLIFY, resolveAppUrl } from '@constants/env';
import { OAuth2Client } from './OAuth2Client';

export * from './helpers';
export * from './OAuth2Client';

let oauth2Client: OAuth2Client;

export const getOAuth2Client = ({
	clientId,
	redirectUri,
	baseURL,
}: {
	clientId: string;
	redirectUri?: string;
	baseURL: string;
}) => {
	if (!oauth2Client) {
		oauth2Client = new OAuth2Client({
			audience: 'laterpay',
			authorizationServerUri: `${baseURL}/auth`,
			clientId,
			providerId: 'tapper-sso',
			redirectUri,
			// redirectUri: NETLIFY
			// 	? 'https://tapper-sso-deploy-preview-redirect.netlify.app/.netlify/functions/redirect'
			// 	: resolveAppUrl(),
			scope: 'read write offline_access',
			tokenServerUri: `${baseURL}/token`,
		});
	}

	return oauth2Client;
};
