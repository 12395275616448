import { h } from 'preact';

import * as S from './styled';

interface Props extends h.JSX.HTMLAttributes<HTMLButtonElement> {}

const Button = ({ children, className, ...buttonProps }: Props) => {
	return <S.Button {...buttonProps}>{children}</S.Button>;
};

export default Button;
