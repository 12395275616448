import { Fragment, h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import TapperApiClient from '../../../shared/api/TapperApiClient';
import { supportedCountries } from '../../../utils';
import { MerchantData, FormStatus } from '../../types';
import Success from '../Success';

import * as S from '../../styled';

interface BasicInfoProps {
	merchant: MerchantData;
	tapperApiClient: TapperApiClient;
	onKYCSuccess: () => void;
}

const days = new Array(31).fill('');
const startYear = new Date().getFullYear() - 12;
const years = Array.from(new Array(100), (value, index) => startYear - index);

function pad(number: number): string {
	return number > 9 ? number.toString() : `0${number.toString()}`;
}

export default function BasicInfo({
	merchant,
	tapperApiClient,
	onKYCSuccess,
}: BasicInfoProps) {
	const [formStatus, setFormStatus] = useState<FormStatus>('default');
	const [error, setError] = useState<string>('');
	const [firstName, setFirstName] = useState<string>(merchant.first_name);
	const [lastName, setLastName] = useState<string>(merchant.last_name);
	// TODO: Endpoint doesn't return date of birth, hence no default value here – confirm with ST this is expected
	const [dobDay, setDobDay] = useState<number>(0);
	const [dobMonth, setDobMonth] = useState<number>(0);
	const [dobYear, setDobYear] = useState<number>(0);
	const [dob, setDob] = useState<string>('');
	const [country, setCountry] = useState<string>(merchant.country);

	useEffect(() => {
		setDob(`${dobYear}-${pad(dobMonth)}-${pad(dobDay)}`);
	}, [dobDay, dobMonth, dobYear]);

	useEffect(() => {
		setError('');

		if (firstName && lastName && dob && country) {
			setFormStatus('valid');
		} else {
			setFormStatus('default');
		}
	}, [firstName, lastName, dob, country]);

	useEffect(() => {
		error && setFormStatus('error');
	}, [error]);

	async function handleSubmit(e) {
		e.preventDefault();

		setFormStatus('submitting');

		try {
			const data = {
				id: merchant.id,
				first_name: firstName,
				last_name: lastName,
				country,
				dob,
				url: window.location.origin,
			};
			await tapperApiClient.updateMerchantInfo(data);
			setFormStatus('submitted');
			onKYCSuccess();
		} catch (error) {
			setError(error.message);
		}
	}

	return (
		<Fragment>
			{formStatus === 'submitted' ? (
				<Success />
			) : (
				<S.Form onSubmit={handleSubmit}>
					<S.Title>Tell Us Who You Are</S.Title>

					{error && <S.ErrorMessage>{error}</S.ErrorMessage>}

					<S.InputGroup style={{ width: 'calc(50% - 6px)' }}>
						<S.InputLabel for="cto_kyc_first_name">First Name</S.InputLabel>
						<S.FormInput
							type="text"
							id="cto_kyc_first_name"
							value={firstName}
							placeholder="First Name"
							onChange={e => setFirstName(e.currentTarget.value)}
							required={true}
						/>
					</S.InputGroup>
					<S.InputGroup style={{ width: 'calc(50% - 6px)' }}>
						<S.InputLabel for="cto_kyc_last_name">Last Name</S.InputLabel>
						<S.FormInput
							type="text"
							id="cto_kyc_last_name"
							value={lastName}
							placeholder="Last Name"
							onChange={e => setLastName(e.currentTarget.value)}
							required={true}
						/>
					</S.InputGroup>
					<S.InputGroup>
						<S.InputLabel for="cto_kyc_birthdate_day">Birth Date</S.InputLabel>
						<S.SelectWrapperFlex>
							<S.SelectWrapper>
								<S.Select
									id="cto_kyc_birthdate_day"
									onChange={e => setDobDay(e.currentTarget.value)}
								>
									<option value="" selected disabled>
										Day
									</option>
									{days.map((index, day) => (
										<option key={day + 1} value={day + 1}>
											{day + 1}
										</option>
									))}
								</S.Select>
							</S.SelectWrapper>
							<S.SelectWrapper>
								<S.Select onChange={e => setDobMonth(e.currentTarget.value)}>
									<option value="" selected disabled>
										Month
									</option>
									<option value="1">January</option>
									<option value="2">February</option>
									<option value="3">March</option>
									<option value="4">April</option>
									<option value="5">May</option>
									<option value="6">June</option>
									<option value="7">July</option>
									<option value="8">August</option>
									<option value="9">September</option>
									<option value="10">October</option>
									<option value="11">November</option>
									<option value="12">December</option>
								</S.Select>
							</S.SelectWrapper>
							<S.SelectWrapper>
								<S.Select onChange={e => setDobYear(e.currentTarget.value)}>
									<option value="" selected disabled>
										Year
									</option>
									{years.map(value => (
										<option key={value} value={value}>
											{value}
										</option>
									))}
								</S.Select>
							</S.SelectWrapper>
						</S.SelectWrapperFlex>
					</S.InputGroup>
					<S.InputGroup>
						<S.InputLabel for="cto_kyc_country">Country</S.InputLabel>
						<S.SelectWrapper>
							<S.Select
								id="cto_kyc_country"
								onChange={e => setCountry(e.currentTarget.value)}
								required={true}
							>
								<option value="" selected disabled>
									Country
								</option>
								{Object.keys(supportedCountries).map((code, index) => (
									<option value={code} key={index} selected={country === code}>
										{supportedCountries[code]}
									</option>
								))}
							</S.Select>
						</S.SelectWrapper>
					</S.InputGroup>
					<S.Button type="submit" disabled={formStatus !== 'valid'}>
						{formStatus === 'submitting' ? 'Please wait…' : 'Submit'}
					</S.Button>
					<S.Paragraph>
						<b>Why are we we requesting this information?</b>
						<br />
						In order to collect money on Contribute.to we are required to record your
						legal name & date of birth.
					</S.Paragraph>
				</S.Form>
			)}
		</Fragment>
	);
}
