type TapperApiUrl = `https://tapi.${'sbx.' | ''}laterpay.net`;
type ContributeToUrl = `https://signon.${'sbx.' | ''}contribute.to`;
type Oauth2Url = `https://auth.${'sbx.' | ''}laterpay.net/oauth2`;

interface Config {
	stripePublishableKey: string;
	tapperApiUrl: TapperApiUrl;
	contributeToUrl: ContributeToUrl;
	oauth2Url: Oauth2Url;
}

const test: Config = {
	stripePublishableKey: 'pk_test_01AMnwndAdxYH7uWf0NzObg2',
	tapperApiUrl: 'https://tapi.sbx.laterpay.net',
	contributeToUrl: 'https://signon.sbx.contribute.to',
	oauth2Url: 'https://auth.sbx.laterpay.net/oauth2',
};

const prod: Config = {
	stripePublishableKey: 'pk_live_ELR1bZxfAA8FPPfIZJsGwAq1',
	tapperApiUrl: 'https://tapi.laterpay.net',
	contributeToUrl: 'https://signon.contribute.to',
	oauth2Url: 'https://auth.laterpay.net/oauth2',
};

export default (testMode = false) => (testMode ? test : prod);
