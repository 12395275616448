import { TapperSSOProvider } from './tapper';
import RegistrationComponent from './component';
import { GuestRegistrationComponentProps, GuestRegistrationRenderOptions } from './types';
import BaseComponent from '../shared/BaseComponent';

export class GuestRegistration extends BaseComponent<GuestRegistrationComponentProps> {
	render(
		selector: string,
		{
			email,
			clientId,
			redirectBack,
			onRegistrationSuccess,
		}: GuestRegistrationRenderOptions
	) {
		super.renderComponent({
			selector,
			Component: RegistrationComponent,
			componentProps: {
				email,
				tapperSSOProvider: new TapperSSOProvider({
					email,
					testMode: this.testMode,
					clientId,
					redirectBack,
				}),
				onRegistrationSuccess,
			},
		});
	}

	destroy() {
		super.destroyComponent();
	}
}
