import styled from 'styled-components';

export const Container = styled.div`
	align-items: center;
	color: #989898;
	display: flex;
	flex-flow: row nowrap;
	font-family: Helvetica Neue;
	font-size: 1rem;
	line-height: 24px;
	user-select: none;
	width: 100%;
`;

interface LineProps {
	noMargin?: boolean;
}

export const Line = styled.div<LineProps>`
	flex: 1;
	height: 1px;
	background-color: #f1f1f1;
	margin: ${props => (props.noMargin ? '0' : '0 0.5rem')};
`;
