/*
  TODOs:

  * I realize this is super lazy but for now just create a wrapper component
*/

import { h } from 'preact';
import GuestRegistration from '../../../GuestRegistration/component';
import { TapperSSOProvider } from '../../../GuestRegistration/tapper';

export default function GuestRegistrationWrapper({
	email,
	testMode,
	clientId,
	redirectBack,
}) {
	return (
		<GuestRegistration
			email={email}
			onRegistrationSuccess={() => console.log('yay')}
			tapperSSOProvider={
				new TapperSSOProvider({
					email,
					testMode,
					clientId,
					redirectBack,
				})
			}
		/>
	);
}
