import { useState, useRef, useEffect } from 'preact/hooks';
import { StripeCheckoutProvider } from '../stripe';

const useCreditCardElement = (
	checkoutProvider: StripeCheckoutProvider,
	cardOptions: stripe.elements.ElementsOptions
) => {
	const cardRef = useRef<stripe.elements.Element>();
	const [cardError, setCardError] = useState<string>('');

	useEffect(() => {
		cardRef.current = checkoutProvider.createElement('card', cardOptions);
		cardRef.current.mount('#cto_stripe-credit-card');
		cardRef.current.on('change', ({ error }) => {
			setCardError(error?.message);
		});

		return () => cardRef.current.unmount();
	}, []);

	return {
		cardError,
		setCardError,
		cardRef,
	};
};

export default useCreditCardElement;
