const initStripe = (publishableKey: string): Promise<stripe.Stripe> => {
	return new Promise((resolve, reject) => {
		const script = document.createElement('script');
		script.onload = () => {
			const stripe = window.Stripe(publishableKey);
			resolve(stripe);
		};
		script.onerror = reject;
		script.src = 'https://js.stripe.com/v3';
		document.body.append(script);
	});
};

export default initStripe;
