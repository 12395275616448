import { h } from 'preact';
import Divider from '../../shared/components/Divider';
import { SocialLoginProps } from '../types';

import * as S from '../styled';

export default function SocialLogin({ socialLoginUrls }: SocialLoginProps) {
	return (
		<S.SocialLogin>
			<Divider>OR</Divider>
			<S.SocialOptions>
				<a href={socialLoginUrls.appleUrl}>
					<svg
						width="56"
						height="56"
						viewBox="0 0 100 100"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<circle cx="50" cy="50" r="49.5" stroke="#989898" />
						<path
							d="M42.9248 75.2055C35.3039 75.1616 29 59.6047 29 51.682C29 38.7402 38.7085 35.9069 42.4501 35.9069C44.1363 35.9069 45.9368 36.569 47.5249 37.155C48.6354 37.5637 49.7839 37.9857 50.4227 37.9857C50.805 37.9857 51.706 37.6268 52.5015 37.3118C54.198 36.6364 56.309 35.7971 58.7673 35.7971C58.7717 35.7971 58.7776 35.7971 58.7819 35.7971C60.6175 35.7971 66.1831 36.1999 69.5291 41.2248L70.3129 42.4027L69.1849 43.2539C67.5734 44.4698 64.6331 46.6878 64.6331 51.0814C64.6331 56.2851 67.9631 58.2863 69.5628 59.2488C70.269 59.6736 71 60.1117 71 61.0698C71 61.6953 66.0073 75.1279 58.757 75.1279C56.9829 75.1279 55.7289 74.5946 54.6227 74.1244C53.5035 73.6482 52.538 73.2381 50.9426 73.2381C50.134 73.2381 49.1114 73.6204 48.0287 74.0263C46.5493 74.5784 44.8747 75.2055 42.9746 75.2055H42.9248Z"
							fill="black"
						/>
						<path
							d="M59.5354 24C59.7242 30.8083 54.8552 35.5316 49.9919 35.2354C49.1905 29.8021 54.8546 24 59.5354 24Z"
							fill="black"
						/>
					</svg>
				</a>
				<br />
				<a href={socialLoginUrls.googleUrl}>
					<svg
						width="56"
						height="56"
						viewBox="0 0 100 100"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<circle cx="50" cy="50" r="49.5" stroke="#989898" />
						<path
							d="M38.3081 54.3811L36.8461 59.8388L31.5027 59.9519C29.9058 56.99 29 53.6012 29 50C29 46.5177 29.8469 43.2338 31.3481 40.3423H31.3492L36.1064 41.2145L38.1903 45.9431C37.7541 47.2146 37.5164 48.5796 37.5164 50C37.5166 51.5415 37.7958 53.0185 38.3081 54.3811Z"
							fill="#FBBB00"
						/>
						<path
							d="M70.6331 46.0769C70.8742 47.3473 71 48.6592 71 50C71 51.5035 70.8419 52.97 70.5408 54.3846C69.5185 59.1985 66.8473 63.4019 63.147 66.3766L63.1458 66.3754L57.1539 66.0697L56.3059 60.7758C58.7613 59.3358 60.6801 57.0823 61.6909 54.3846H50.4617V46.0769H61.8547H70.6331Z"
							fill="#518EF8"
						/>
						<path
							d="M63.1458 66.3754L63.1469 66.3766C59.5481 69.2692 54.9765 71 50 71C42.0027 71 35.0496 66.53 31.5027 59.952L38.3081 54.3812C40.0815 59.1142 44.6473 62.4835 50 62.4835C52.3007 62.4835 54.4562 61.8616 56.3057 60.7758L63.1458 66.3754Z"
							fill="#28B446"
						/>
						<path
							d="M63.4042 33.8346L56.6011 39.4042C54.6869 38.2077 52.4242 37.5165 50 37.5165C44.5261 37.5165 39.875 41.0403 38.1904 45.9431L31.3492 40.3423H31.3481C34.8431 33.6038 41.8838 29 50 29C55.0954 29 59.7673 30.815 63.4042 33.8346Z"
							fill="#F14336"
						/>
					</svg>
				</a>
			</S.SocialOptions>
		</S.SocialLogin>
	);
}
