import styled from 'styled-components';

export const Button = styled.button`
	background: rgb(96, 37, 118);
	border: none;
	border-radius: 100px;
	color: #ffffff;
	cursor: pointer;
	font-family: Futura;
	font-size: 1rem;
	font-style: normal;
	font-weight: 700;
	height: 3rem;
	text-align: center;
	width: 100%;

	&:disabled {
		background: rgba(96, 37, 118, 0.3);
	}
`;
