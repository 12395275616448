class CachedString {
	private storageKey: string;
	private value: string | undefined;

	public getValue() {
		return this.value ?? sessionStorage.getItem(this.storageKey);
	}

	public setValue(value: string) {
		this.value = value;
		sessionStorage.setItem(this.storageKey, this.value);
	}

	/**
	 * CachedString caches string value in the storage and provides methods
	 * to get and set that value.
	 * @param storageKey Unique key referencing cached value in storage
	 */
	constructor(storageKey: string) {
		this.storageKey = storageKey;
	}
}

export default CachedString;
