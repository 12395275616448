import { useState, useRef, useEffect } from 'preact/hooks';
import { validatePassword } from '../../utils';

const usePasswordField = () => {
	const [passwordError, setPasswordError] = useState<string>('');
	const passwordRef = useRef<HTMLInputElement>();

	useEffect(() => {
		const password = passwordRef.current;

		password?.addEventListener('change', validatePasswordField);
		return () => password?.removeEventListener('change', validatePasswordField);
	}, []);

	function validatePasswordField() {
		const passwordValidationMessage = validatePassword(passwordRef.current.value);
		setPasswordError(passwordValidationMessage);
		return !passwordValidationMessage;
	}

	return {
		passwordRef,
		passwordError,
		validatePasswordField,
		setPasswordError,
	};
};

export default usePasswordField;
