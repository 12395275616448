import base64url from 'base64url';
import { nanoid } from 'nanoid';
import { Sha256 } from '@aws-crypto/sha256-browser';
import { Buffer } from 'buffer';
window.Buffer = Buffer;

export const getRandomString = (size: number) => {
	return nanoid(size);
};

export const sha256 = async (data: string) => {
	const hash = new Sha256();
	hash.update(data);
	return await hash.digest();
};

export const pkceChallengeFromVerifier = async (verifier: string) => {
	const hash = await sha256(verifier);
	return base64url.encode(Buffer.from(hash));
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const encodeJsonToState = (obj: any) => {
	return base64url.encode(JSON.stringify(obj));
};

export const decodeStateToJson = <T>(state: string) => {
	return JSON.parse(base64url.decode(state)) as T;
};
