import { useEffect, useRef, useState } from 'preact/hooks';
import { StripeCheckoutProvider } from '../stripe';

type UsePaymentRequestButtonOptions = {
	paymentRequestOptions: stripe.paymentRequest.StripePaymentRequestOptions;
	paymentRequestButtonStyle: stripe.elements.PaymentRequestButtonStyleOptions;
	onPaymentRequestPaymentMethodEvent: (response: stripe.paymentRequest.StripePaymentMethodPaymentResponse) => void;
};

export const usePaymentRequestButton = (checkoutProvider: StripeCheckoutProvider, options: UsePaymentRequestButtonOptions) => {
	const paymentRequestButtonRef = useRef<HTMLDivElement>();
	const paymentRequestButton = useRef<stripe.elements.Element>();
	const [loaded, setLoaded] = useState<boolean>(false);

	useEffect(() => {
		createPaymentRequestButton();
		return () => paymentRequestButton.current.unmount();
	}, []);

	async function createPaymentRequestButton() {
		if (!options.paymentRequestOptions) {
			return;
		}

		const paymentRequest = checkoutProvider.createPaymentRequest(options.paymentRequestOptions);

		const supportsPaymentRequest = await paymentRequest.canMakePayment();
		if (supportsPaymentRequest) {
			paymentRequestButton.current = checkoutProvider.createPaymentRequestButton(paymentRequest, options.paymentRequestButtonStyle);

			paymentRequestButton.current.mount('#cto_payment-request-button');

			paymentRequest.on('paymentmethod', options.onPaymentRequestPaymentMethodEvent);

			paymentRequestButtonRef.current.style.opacity = '1';
			setLoaded(true)
		} else {
			console.warn('Device does not support payment request.');
			paymentRequestButtonRef.current.style.display = 'none';
		}
	}

	return {
		paymentRequestButtonRef,
		loaded
	};
};

export default usePaymentRequestButton;
