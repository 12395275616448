import styled from 'styled-components';
import * as InputGlobal from '../shared/components/Input/styled';
import Divider from '../shared/components/Divider';

export const Container = styled.div`
	${InputGlobal.Container} {
		margin-bottom: 12px;
	}
`;

export const PasswordInputContainer = styled.div`
	${InputGlobal.Container} {
		background-color: white;
		border-color: #989898;
		font-weight: normal;
		margin-bottom: 40px;
	}
`;

export const Title = styled.h1`
	font-family: Helvetica Neue;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 17px;
	letter-spacing: 0.005em;
	text-align: center;
`;

export const ErrorMessage = styled.p`
	line-height: 1.4;
	&:first-of-type {
		color: #e25950;
	}
	a {
		color: #602576;
	}
`;

export const SocialLogin = styled.div`
	text-align: center;
`;

export const SocialOptions = styled.div`
	display: inline-flex;
	a {
		margin: 0 5px;
	}
`;

export const RegistrationSuccess = styled.p`
	text-align: center;
	font-size: 0.875rem;
	line-height: 1.4;
`;

export const DividerWithVerticalPadding = styled(Divider)`
	padding: 16px 0;
`;
