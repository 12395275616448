import * as Type from './types';

export const defaultMerchantData: Type.MerchantData = {
	id: '',
	first_name: '',
	last_name: '',
	country: '',
	dob: '',
	url: '',
};
