import { h } from 'preact';
import { useState } from 'preact/hooks';
import Button from '../../../shared/components/Button';
import * as S from './styled';
import * as CheckoutStyles from '../../styled';

export default function RecurringContributions({
	formState,
	error,
	paymentMethod,
	onMakeRecurringPayment,
	processingPayment,
}) {
	const [creditCard, setCreditCard] = useState('saved');
	return (
		<S.Container formState={formState}>
			<S.InputContainer>
				<S.Label className="cto-label">
					<input
						type="radio"
						value="saved"
						checked={creditCard === 'saved'}
						onChange={e => setCreditCard(e.currentTarget.value)}
					/>
					Saved Card
				</S.Label>
				{creditCard === 'saved' && paymentMethod && (
					<S.SavedCard>
						<svg
							width="18"
							height="14"
							viewBox="0 0 18 14"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<rect
								x="1"
								y="1"
								width="16"
								height="12"
								rx="1"
								stroke="#363740"
								stroke-opacity="0.3"
								stroke-width="0.25"
							/>
							<g clip-path="url(#clip0)">
								<path
									d="M7.13342 9.01111L7.84453 5.01111H8.95564L8.26676 9.01111H7.13342Z"
									fill="#3C58BF"
								/>
								<path
									d="M7.13342 9.01111L8.04453 5.01111H8.95564L8.26676 9.01111H7.13342Z"
									fill="#293688"
								/>
								<path
									d="M12.2889 5.05559C12.0667 4.9667 11.7111 4.87781 11.2667 4.87781C10.1556 4.87781 9.35558 5.43336 9.35558 6.23336C9.35558 6.83336 9.91114 7.14447 10.3556 7.34447C10.8 7.54447 10.9334 7.67781 10.9334 7.85558C10.9334 8.12225 10.5778 8.25559 10.2667 8.25559C9.82225 8.25559 9.57781 8.18892 9.20003 8.03336L9.04447 7.9667L8.88892 8.87781C9.15558 8.98892 9.64447 9.10003 10.1556 9.10003C11.3334 9.10003 12.1111 8.54447 12.1111 7.70003C12.1111 7.23336 11.8223 6.87781 11.1556 6.58892C10.7556 6.38892 10.5111 6.27781 10.5111 6.07781C10.5111 5.90003 10.7111 5.72225 11.1556 5.72225C11.5334 5.72225 11.8 5.78892 12 5.87781L12.1111 5.92225L12.2889 5.05559Z"
									fill="#3C58BF"
								/>
								<path
									d="M12.2889 5.05559C12.0667 4.9667 11.7111 4.87781 11.2667 4.87781C10.1556 4.87781 9.55558 5.43336 9.55558 6.23336C9.55558 6.83336 9.91114 7.14447 10.3556 7.34447C10.8 7.54447 10.9334 7.67781 10.9334 7.85558C10.9334 8.12225 10.5778 8.25559 10.2667 8.25559C9.82225 8.25559 9.57781 8.18892 9.20003 8.03336L9.04447 7.9667L8.88892 8.87781C9.15558 8.98892 9.64447 9.10003 10.1556 9.10003C11.3334 9.10003 12.1111 8.54447 12.1111 7.70003C12.1111 7.23336 11.8223 6.87781 11.1556 6.58892C10.7556 6.38892 10.5111 6.27781 10.5111 6.07781C10.5111 5.90003 10.7111 5.72225 11.1556 5.72225C11.5334 5.72225 11.8 5.78892 12 5.87781L12.1111 5.92225L12.2889 5.05559Z"
									fill="#293688"
								/>
								<path
									d="M14.2001 5.01111C13.9334 5.01111 13.7334 5.03333 13.6223 5.3L11.9556 9.01111H13.1556L13.3779 8.34444H14.8001L14.9334 9.01111H16.0001L15.0667 5.01111H14.2001ZM13.689 7.67777C13.7556 7.47777 14.1334 6.5 14.1334 6.5C14.1334 6.5 14.2223 6.25555 14.289 6.1L14.3556 6.47777C14.3556 6.47777 14.5779 7.47778 14.6223 7.7H13.689V7.67777Z"
									fill="#3C58BF"
								/>
								<path
									d="M14.4667 5.01111C14.2001 5.01111 14.0001 5.03333 13.889 5.3L11.9556 9.01111H13.1556L13.3779 8.34444H14.8001L14.9334 9.01111H16.0001L15.0667 5.01111H14.4667ZM13.689 7.67777C13.7779 7.45555 14.1334 6.5 14.1334 6.5C14.1334 6.5 14.2223 6.25555 14.289 6.1L14.3556 6.47777C14.3556 6.47777 14.5779 7.47778 14.6223 7.7H13.689V7.67777Z"
									fill="#293688"
								/>
								<path
									d="M5.08879 7.8111L4.97767 7.23332C4.77767 6.56666 4.13323 5.83332 3.42212 5.47777L4.42212 9.03332H5.62212L7.42212 5.03333H6.22212L5.08879 7.8111Z"
									fill="#3C58BF"
								/>
								<path
									d="M5.08879 7.8111L4.97767 7.23332C4.77767 6.56666 4.13323 5.83332 3.42212 5.47777L4.42212 9.03332H5.62212L7.42212 5.03333H6.44434L5.08879 7.8111Z"
									fill="#293688"
								/>
								<path
									d="M2 5.01111L2.2 5.05555C3.62222 5.38889 4.6 6.23333 4.97778 7.23333L4.57778 5.34444C4.51111 5.07778 4.31111 5.01111 4.06667 5.01111H2Z"
									fill="#FFBC00"
								/>
								<path
									d="M2 5.01111C3.42222 5.34444 4.6 6.21111 4.97778 7.21111L4.6 5.63333C4.53333 5.36666 4.31111 5.21111 4.06667 5.21111L2 5.01111Z"
									fill="#F7981D"
								/>
								<path
									d="M2 5.01111C3.42222 5.34444 4.6 6.21111 4.97778 7.21111L4.71111 6.34444C4.64444 6.07778 4.55556 5.81111 4.24444 5.7L2 5.01111Z"
									fill="#ED7C00"
								/>
								<path
									d="M6.20001 7.67779L5.44446 6.92223L5.0889 7.76668L5.00001 7.21112C4.80001 6.54446 4.15557 5.81112 3.44446 5.45557L4.44446 9.01112H5.64446L6.20001 7.67779Z"
									fill="#051244"
								/>
								<path
									d="M8.26676 9.0111L7.3112 8.03333L7.13342 9.0111H8.26676Z"
									fill="#051244"
								/>
								<path
									d="M10.8223 7.6333C10.9111 7.72219 10.9556 7.78886 10.9334 7.87775C10.9334 8.14441 10.5778 8.27775 10.2667 8.27775C9.82225 8.27775 9.57781 8.21108 9.20003 8.05552L9.04447 7.98886L8.88892 8.89997C9.15558 9.01108 9.64447 9.12219 10.1556 9.12219C10.8667 9.12219 11.4445 8.92219 11.7778 8.56664L10.8223 7.6333Z"
									fill="#051244"
								/>
								<path
									d="M12.1111 9.01109H13.1556L13.3778 8.34442H14.8L14.9334 9.01109H16L15.6223 7.38886L14.2889 6.09998L14.3556 6.45553C14.3556 6.45553 14.5778 7.45553 14.6223 7.67775H13.6889C13.7778 7.45553 14.1334 6.49998 14.1334 6.49998C14.1334 6.49998 14.2223 6.25553 14.2889 6.09998"
									fill="#051244"
								/>
							</g>
							<defs>
								<clipPath id="clip0">
									<rect width="14" height="14" fill="white" transform="translate(2)" />
								</clipPath>
							</defs>
						</svg>
						<p>xxxx xxxx xxxx {paymentMethod.card.last4}</p>
					</S.SavedCard>
				)}
			</S.InputContainer>
			<S.InputContainer>
				{paymentMethod ? (
					<S.Label>
						<input
							type="radio"
							value="another"
							checked={creditCard === 'another'}
							onChange={e => setCreditCard(e.currentTarget.value)}
						/>
						Use another card
					</S.Label>
				) : (
					<S.Label for="cto_stripe-credit-card">Card Information</S.Label>
				)}
				<CheckoutStyles.CardInput
					data-error-message={error}
					id="cto_stripe-credit-card"
					style={{
						display: creditCard === 'another' ? 'block' : 'none',
						marginBottom: 0, // Temporary for now.
					}}
				/>
			</S.InputContainer>
			<Button onClick={!processingPayment ? handleClick : undefined}>
				{processingPayment ? 'Processing...' : 'Make Contribution'}
			</Button>
			{!paymentMethod && (
				<S.RecurringPaymentsCopy
					style={{
						marginTop: -16, // Temporary for now.
					}}
				>
					By confirming your subscription, you allow Contribute.to to save and charge your
					card for this payment and future payments in accordance with our terms.
				</S.RecurringPaymentsCopy>
			)}
		</S.Container>
	);

	function handleClick() {
		onMakeRecurringPayment(creditCard);
	}
}
