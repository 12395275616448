import styled from 'styled-components';

export const Container = styled.div`
	padding: 0.5rem;
	color: #363740;
	background-color: #f4f4f4;
	border: none;
	border-radius: 0.25rem;
	width: 100%;
	font-weight: 500;
	font-family: inherit;
	font-size: 1rem;
	box-sizing: border-box;
	border: 1px solid transparent;
	position: relative;
	&:after {
		content: attr(data-error-message);
		position: absolute;
		top: 100%;
		font-size: 0.675rem;
		color: #e25950;
		padding: 0.25rem 0;
		left: 0;
	}
	&[data-error-message]:not([data-error-message='']) {
		border-color: #e25950;
	}
`;

export const Input = styled.input`
	background: none;
	height: 100%;
	width: 100%;
	color: inherit;
	font: inherit;
	border: none;
	display: block;
	outline: none;
	&::placeholder {
		color: #989898;
	}
`;
