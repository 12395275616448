import { h, ComponentChildren } from 'preact';

import * as S from './styled';

const Divider = ({
	noMargin,
	children,
	className,
}: {
	noMargin?: boolean;
	children?: ComponentChildren;
	className?: string;
}) => (
	<S.Container className={className}>
		<S.Line noMargin={noMargin} />
		{children}
		<S.Line noMargin={noMargin} />
	</S.Container>
);

export default Divider;
