import TapperApiClient from '../shared/api/TapperApiClient';
import BaseComponent from '../shared/BaseComponent';
import { getOAuth2Client } from '../shared/oauth2Client';
import KYCComponent from './component';
import { KYCComponentProps, KYCRenderOptions } from './types';
import getEnv from '../getEnv';

export class KYC extends BaseComponent<KYCComponentProps> {
	render(
		selector: string,
		{ clientId, redirectUri, onKYCSuccess, onKYCError }: KYCRenderOptions
	) {
		super.renderComponent({
			selector,
			Component: KYCComponent,
			componentProps: {
				onKYCSuccess,
				onKYCError,
				oauth2Client: getOAuth2Client({
					clientId,
					redirectUri,
					baseURL: getEnv(this.testMode).oauth2Url,
				}),
				tapperApiClient: new TapperApiClient({
					baseURL: getEnv(this.testMode).tapperApiUrl,
				}),
			},
		});
	}

	destroy() {
		super.destroyComponent();
	}
}
